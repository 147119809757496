/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 13:15:33
 * @LastEditTime: 2021-11-12 12:08:43
 */

import { request } from "../../utils/request/index";

// 获取 license information
export function getLicneseByLicenseNumber(licenseNumber) {
  try {
    return request.get(`/license/${licenseNumber}`);
  } catch (error) {
    throw error;
  }
}

export function getTotalPrice(amount) {
  const url = `/license/price/${amount}`;
  return request.get(url);
}

// 获取单价
export function getUnitPrice(amount) {
  const url = `/license/unitPrice/${amount}`;
  return request.get(url);
}

// 支付结果回调函数
export function onPaymentCb(params) {
  // console.log('request: ', url);
  // console.log('支付结果回调函数 payload: ', params);

  return request.post(`/license/payment`, {
    data: params,
  });
}
