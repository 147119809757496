/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 11:41:07
 * @LastEditTime: 2023-02-18 16:10:26
 */
import React, { useState } from 'react';
import { Router, Route, Switch } from 'dva/router';
import NotFound from './components/404';
import Home from './components/home';
import KeywriterRenewalPage from './pages/keywriter/KeywriterRenewalPage';
import CreateKwLicensePage from './pages/keywriter/CreateLicensePage'
import KeywriterActivationPage from './pages/keywriter/KeywriterActivationPage';
import CloudashurLicenseActivationPage from './pages/cloudashur/CloudashurLicenseActivationPage';
import KeywriterChangePinPage from './pages/keywriter/KeywriterChangePinPage'; // 修改证书PIN的页面
import { IntlProvider } from 'react-intl'; // 导入多语言
import intlText from './locale/messages'; // 导入自定义的多语言词库
import { ConfigProvider } from 'antd';
import './App.css';

const RouterConfig = (({ history }) => {
    const [locale] = useState('en');

    return <div className="App" style={{
        backgroundColor: '#0A0A0B',
        backgroundImage: "url(/img/bg.svg)",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right top',

        height: 1000,
        minHeight: '100%',
        width: '100%',
        position: 'absolute',
        top: '0px',
        bottom: '0px',

    }} >
        <IntlProvider locale={locale} messages={intlText[locale]}>
            <ConfigProvider locale={locale}>
                <Router history={history}>
                    <Switch>
                        <Route path="/" exact component={Home} />
                        <Route path='/keywriter/renew/:licenseNumber?' exact component={KeywriterRenewalPage} />
                        <Route path='/keywriter/changePin/:licenseNumber' exact component={KeywriterChangePinPage} />
                        <Route path='/keywriter/sd/activation' exact component={KeywriterActivationPage} />
                        <Route path='/keywriter/sd/license/new' exact component={CreateKwLicensePage} />
                        <Route path='/keywriter/activation/:licenseNumber/:signature/:data?' exact component={KeywriterActivationPage} />
                        <Route path='/cloudashur/license/activation' exact component={CloudashurLicenseActivationPage} />
                        <Route component={NotFound} />
                    </Switch>
                </Router>
            </ConfigProvider>
        </IntlProvider>
    </div>
});
export default RouterConfig;