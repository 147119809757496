import BasicPage from "../../components/basicPage/index";
import React from "react";
import CreateLicenseContainer from "../../components/keywriter/create/CreateLicenseContainer";
import Header from "../../components/keywriter/activate/Header";
// import { connect } from 'dva';

/**
 *
 * @param {*} param0
 */
const CreateKwLicensePage = ({
  location,
  match: {
    params: { licenseNumber, signature, data },
  },
}) => {
  console.log("location:", location);

  const activatationContainer = () => {
    // data 是加密过的注册用户数据
    return (
      <CreateLicenseContainer
        licenseNumber={licenseNumber}
        signature={signature}
        data={data}
      />
    );
  };

  return (
    <BasicPage
      HeaderComponent={Header}
      ContentComponent={activatationContainer}
    />
  );
  // return licenseNumber ? <BasicPage HeaderComponent={Header} ContentComponent={activatationContainer} /> : null
};

export default CreateKwLicensePage;
