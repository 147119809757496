import React from "react";
import { Card, Result } from "antd";
// import { useIntl } from 'react-intl';// 导入多语言
import { getIcon } from "../../Icon";
import ActivateResult from "./ActivateResult";
import ActivateForm from "./ActivateForm";
import ActivateFormManually from "./ActivateFormManually";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

/**
 * Keywriter activation container
 * @param string data 加密后的用户登记数据
 */
const ActivatationContainer = ({ licenseNumber, signature, data }) => {
  // const intl = useIntl();

  return (
    <Card
      size="small"
      headStyle={{ height: 50 }}
      title={
        <div>
          <span style={{ float: "left", marginTop: "-30px" }}>
            {getIcon("istorage", 90)}
          </span>
          <span style={{ float: "right", marginTop: "-42px" }}>
            {getIcon("keywriter", 125)}
          </span>
        </div>
      }
      style={{
        width: "100%",
        borderRadius: 5,
        height: "auto",
      }}
      bodyStyle={{
        height: "auto",
      }}
      actions={[
        <a href="tel:+4402089916260">
          <PhoneOutlined key="setting" /> +44 (0)20 8991 6260
        </a>,
        <a href="mailto:support@istorage-uk.com">
          <MailOutlined key="ellipsis" /> support@istorage-uk.com
        </a>,
      ]}
    >
      {signature === "form" ? (
        // Keywriter Activation form
        licenseNumber ? (
          <ActivateForm licenseNumber={licenseNumber} />
        ) : (
          // TODO 没有证书编号的时候，允许用户手动输入
          <Result
            status="error"
            title="Invalid license number"
            subTitle="Sorry, we can not find your license."
          />
        )
      ) : licenseNumber ? (
        // Keywriter Activation result
        <ActivateResult
          licenseNumber={licenseNumber}
          signature={signature}
          data={data}
        />
      ) : (
        // 既没有传递licenseNumber, 有没有传递 signature。那么，要求用户手动输入 licenseNumber
        <ActivateFormManually licenseNumber={undefined} />
      )}
    </Card>
  );
};

export default ActivatationContainer;
