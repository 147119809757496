/*
 * @Author: Peng Liu
 * @Date: 2022-01-12 09:33:55
 * @LastEditTime: 2023-02-18 18:36:08
 */
export default {
    sdBatchLicenseForTwinPack: 'sd-batch',


    sdLicenseForNormalPurchase: 'sd-single',


    cloudAshurLicenseForNormalPurchase: 'cloudashur-single'
}