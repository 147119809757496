/*
 * @Author: Peng Liu
 * @Date: 2023-02-18 14:56:42
 * @LastEditTime: 2023-02-18 15:09:17
 */
import BasicPage from "../../components/basicPage/index";
import React from "react";
import CloudashurActivatationContainer from "../../components/keywriter/activate/cloudashur/CloudashurActivatationContainer";

import Header from "../../components/keywriter/activate/cloudashur/CloudashurHeader";

/**
 *
 * @param {*} param0
 */
const CloudashurLicenseActivationPage = ({
  match: {
    params: { licenseNumber, signature, data },
  },
}) => {
  // console.log('signature:', signature);

  const activatationContainer = () => {
    // data 是加密过的注册用户数据
    return (
      <CloudashurActivatationContainer
        licenseNumber={licenseNumber}
        signature={signature}
        data={data}
      />
    );
  };

  return (
    <BasicPage
      HeaderComponent={Header}
      ContentComponent={activatationContainer}
    />
  );
};

export default CloudashurLicenseActivationPage;
