/*
 * @Author: Peng Liu
 * @Date: 2021-08-02 17:11:32
 * @LastEditTime: 2021-11-10 13:49:59
 */
import dva from 'dva';
import './index.css';
import { createBrowserHistory as createHistory } from "history"

// 初始化 dva
const app = dva({ history: createHistory() });

// 这里可以导入一些 dva 插件，如果不需要就不用写
// app.use({});

// 这里是导入 model 后续我们新建一个dva model 模块就要这里引入才能使用
app.model(require('./models/keywriter/keywriterRenewModel').default);
app.model(require('./models/keywriter/keywriterInfoModel').default);

// 导入路由 类似之前代码里的 <Router />
app.router(require('./router').default);

app.start('#root');