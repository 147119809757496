import React from "react";
import { Card } from "antd";
// import { useIntl } from 'react-intl';// 导入多语言
import { getIcon } from "../../Icon";
import RenewForm from "./RenewForm";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

/**
 * Keywriter renewal card
 */
const RenewCard = ({ licenseNumber }) => {
  // const intl = useIntl();

  return (
    <Card
      size="small"
      headStyle={{ height: 50 }}
      title={
        <div>
          <span style={{ float: "left", marginTop: "-30px" }}>
            {getIcon("istorage", 90)}
          </span>
          <span style={{ float: "right", marginTop: "-42px" }}>
            {getIcon("keywriter", 125)}
          </span>
        </div>
      }
      style={{
        width: "100%",
        borderRadius: 5,
        height: "auto", //控制card整体外观长度
      }}
      bodyStyle={{
        height: "fit-content",
        // maxHeight: 600,
      }}
      actions={[
        <a href="tel:+4402089916260">
          <PhoneOutlined key="setting" /> +44 (0)20 8991 6260
        </a>,
        <a href="mailto:support@istorage-uk.com">
          <MailOutlined key="ellipsis" /> support@istorage-uk.com
        </a>,
      ]}
    >
      {/* Keywriter renew form */}
      <RenewForm licenseNumber={licenseNumber} />
    </Card>
  );
};

export default RenewCard;
