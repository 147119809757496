import BasicPage from '../../components/basicPage/index';
import React from 'react';
import ChangePinContainer from '../../components/keywriter/pin/Container';
import Header from '../../components/keywriter/pin/Header';
// import { connect } from 'dva';

/**
 * 
 * @param {*} param0 
 */
const KeywriterChangePinPage = ({ match: { params: { licenseNumber } } }) => {

    const changePinContainer = () => {
        // data 是加密过的注册用户数据
        return <ChangePinContainer licenseNumber={licenseNumber} />
    }

    return licenseNumber ? <BasicPage HeaderComponent={Header} ContentComponent={changePinContainer} /> : null
}

export default (KeywriterChangePinPage);