import { Layout, Row, Col } from "antd";
import BasicFooter from "../footer/index";
import React from "react";

const { Header, Footer, Content } = Layout;

// 基本页面组件
const BasicPage = ({ HeaderComponent, ContentComponent }) => {
  const style = { background: "transparent", color: "#F2F2F2" };

  return (
    <Layout style={style}>
      <Header style={style}>
        <HeaderComponent />
      </Header>

      <Content>
        <Row>
          <Col
            xs={{ span: 22, offset: 0 }}
            sm={{ span: 22, offset: 0 }}
            md={{ span: 20, offset: 6 }}
            lg={{ span: 8, offset: 8 }}
            style={{ margin: "auto" }}
          >
            <ContentComponent />
          </Col>
        </Row>
      </Content>

      <Footer
        style={{
          background: "transparent",
          color: "#F2F2F2",
          marginTop: "auto",
        }}
      >
        <BasicFooter />
      </Footer>
    </Layout>
  );
};

export default BasicPage;
