import { resolvePriceNumberString } from "./resolvePriceNumberString";
import { resolveTotal } from "./resolveTotal";
import { resolveVAT } from "./resolveVAT";

export const resolveTax = ({ totalAmount, countryCode }) => {
  const vat = resolveVAT(countryCode);
  const total = resolveTotal(totalAmount);
  if (0 <= vat && vat < 1) {
    return resolvePriceNumberString(total * vat);
  } else {
    return "0";
  }
};
