import React from "react";
import { Space, Result } from "antd";
// 统一处理提交表格后的返回的结果
export function showActivationSubmitionResult(
  sendActivationFormResponse,
  licenseNumber,
  capacity,
  intl
) {
  console.log("error code:", sendActivationFormResponse.code);
  switch (sendActivationFormResponse.code) {
    case "LicenseNotFound":
      return (
        <Result
          status="error"
          title={intl.formatMessage({ id: "InvalidActivationLinkTitle" })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({ id: "LicenseNotFoundSubtitle" })}
              </span>
            </Space>
          }
        />
      );
    case "LicenseHasActivated":
      return (
        <Result
          status="info"
          title={intl.formatMessage({ id: "licenseActivatedAlreadyTitle" })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({ id: "licenseActivatedAlreadySubtitle" })}
              </span>
              <span>
                {licenseNumber &&
                  `${intl.formatMessage({
                    id: "License Number",
                  })}: ${licenseNumber}`}
              </span>
              <span>
                {capacity &&
                  `${intl.formatMessage({
                    id: "License Capacity",
                  })}: ${capacity}`}
              </span>
            </Space>
          }
        />
      );
    case "LicenseAndPinDisMatch":
      return (
        <Result
          status="error"
          title={intl.formatMessage({
            id: "LicenseAndPinDisMatchTitle",
          })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({
                  id: "LicenseAndPinDisMatchSubtitle",
                })}
              </span>
            </Space>
          }
        />
      );
    case "InvalidFullName":
      return (
        <Result
          status="error"
          title={intl.formatMessage({
            id: "InvalidFullNameTitle",
          })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({
                  id: "InvalidFullNameSubtitle",
                })}
              </span>
            </Space>
          }
        />
      );
    default:
      return (
        <Result
          status="error"
          title={intl.formatMessage({
            id: "licenseActivationFormFailedTitle",
          })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({
                  id: "licenseActivationFormFailedSubtitle",
                })}
              </span>
            </Space>
          }
        />
      );
  }
}
