/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 11:38:12
 * @LastEditTime: 2021-11-03 16:07:09
 */
import { getLicneseByLicenseNumber, getTotalPrice, onPaymentCb, getUnitPrice } from '../../service/keywriter/renewService';
// import { notification } from 'antd';

export default {
    namespace: 'keywriterRenewModel',

    state: {
        license: undefined,//{}, // 当前处理的证书细节信息
        totalPrice: undefined, // 支付金额
        unitPrice: undefined, // 单价金额
        paymentOk: false, // 支付是否成功
        isPaymentCorrect: undefined, // 支付金额是否正确
        updateLicenseCapacityOk: undefined, // 更新容量成功
        isUpdatingLicense: false, // 是否正在更新证书

    },

    effects: {
        *getLicneseByLicenseNumber({ payload: licenseNumber }, { call, put }) {

            const response = yield call(getLicneseByLicenseNumber, licenseNumber);

            const { result, data } = response;

            if (result) {
                yield put({
                    type: 'saveLicense',
                    payload: data,
                });
            } else {
                yield put({
                    type: 'saveLicense',
                    payload: {},
                });
            }
        },
        *getTotalPrice({ payload: amount }, { call, put }) {
            const response = yield call(getTotalPrice, amount);
            const { result, data } = response;

            if (result) {
                yield put({
                    type: 'saveLicensePrice',
                    payload: data.price,

                });
            }
        },
        *getUnitPrice({ payload: amount }, { call, put }) {
            const response = yield call(getUnitPrice, amount);
            const { result, code, data: { price } } = response;

            if (result && code === 'GetLicenseUnitPriceOk') {
                yield put({
                    type: 'saveLicenseUnitPrice',
                    payload: price,
                });
            } else {
                ;
            }
        },
        // 处理支付成功的情况
        *handlePayment({ payload }, { call, put }) {
            const { paid } = payload;
            if (paid) {
                // show upgrading spinner.
                yield put({
                    type: 'setUpdatingLicense',
                    payload: true,
                });
            }

            const response = yield call(onPaymentCb, payload); // call backend to update the license capacity when payment is succeed.
            // close updating spinner
            yield put({
                type: 'setUpdatingLicense',
                payload: false,
            });

            console.log('handlePayment response:', response);

            const { result, data, code } = response;
            if (result && code === 'UpdateLicenseCapacityOk') {
                const { capacity } = data;

                // 支付成功且更新证书成功
                yield put({
                    type: 'paymentAndUpdateCapacityOk',
                    payload: capacity,
                });
            } else {

                // 支付成功，但是支付金额错误
                if (code === 'IncorrectPaidPrice') {
                    yield put({
                        type: 'incorrectPaidPrice',
                        payload: false, // 明确表示更新证书失败
                    });
                } else {
                    // 支付成功，但更新证书失败通知
                    yield put({
                        type: 'paymentOkButUpdateCapacityFailed',
                        payload: false, // 明确表示更新证书失败
                    });
                }
            }
        },

    },

    subscriptions: {
        setup({ dispatch, history }) { }
    },

    reducers: {
        save(state, action) {
            return { ...state, ...action.payload };
        },
        saveLicense(state, action) {
            return {
                ...state,
                license: action.payload
            };
        },
        saveLicensePrice(state, action) {
            return {
                ...state,
                totalPrice: action.payload
            }
        },
        saveLicenseUnitPrice(state, action) {
            return {
                ...state,
                unitPrice: action.payload
            }
        },
        paymentAndUpdateCapacityOk(state, action) {
            const { license } = state;
            const { payload } = action;

            return {
                ...state,
                license: { ...license, capacity: payload },
                updateLicenseCapacityOk: true,
                paymentOk: true,
                isPaymentCorrect: true,
            }

        },
        paymentOkButUpdateCapacityFailed(state, action) {
            const { payload } = action;
            if (payload === false) {
                return {
                    ...state,
                    updateLicenseCapacityOk: false,
                    paymentOk: true,
                    isPaymentCorrect: true,

                }
            }
        },

        // 支付金额错误
        incorrectPaidPrice(state, action) {
            const { payload } = action;
            if (payload === false) {
                return {
                    ...state,
                    updateLicenseCapacityOk: false,
                    paymentOk: true,
                    isPaymentCorrect: false, // 支付金额错误
                }
            }
        },

        setUpdatingLicense(state, action) {
            const { payload } = action;

            return {
                ...state,
                isUpdatingLicense: payload,

            }

        },
    }
}