/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 11:38:12
 * @LastEditTime: 2023-02-19 11:16:09
 */
import { getLicneseByLicenseNumber } from "../../service/keywriter/renewService";
import {
  activateLicnese,
  sendActivationUrlByEmail,
  createNewSDLicense,
} from "../../service/keywriter/activateService";
import { changeLicnesePin } from "../../service/keywriter/licensePinService";
import { message } from "antd";

export default {
  namespace: "keywriterInfoModel",

  state: {
    license: {}, // 当前处理的证书细节信息
    isCheckingLicense: false, // 是否正在获取证书信息
    activationResponse: undefined, // 证书激活的结果
    isActivatingLicense: false, // 标记是否处于激活证书中
    isSubmittingActivationForm: false, // 标记是否提交表格
    sendActivationFormResponse: undefined, // 提交激活证书表格数据的响应结果

    isSubmittingChangePinForm: false, // 是否正在提交修改PIN的表格数据
    changPinResp: undefined, // 提交修改证书表格后得到的响应结果
  },

  effects: {
    *getLicneseByLicenseNumber({ payload: licenseNumber }, { call, put }) {
      yield put({
        type: "setLoadingLicense",
        payload: true,
      });

      try {
        const response = yield call(getLicneseByLicenseNumber, licenseNumber);

        const { result, data } = response;

        if (result) {
          yield put({
            type: "saveLicense",
            payload: data,
          });
        } else {
          yield put({
            type: "saveLicense",
            payload: {},
          });
        }

        yield put({
          type: "setLoadingLicense",
          payload: false,
        });
      } catch (error) {
        // 设置显示结果内容
        yield put({
          type: "setActivationResp",
          payload: error.hasOwnProperty("data") ? error.data : error,
        });

        yield put({
          type: "setLoadingLicense",
          payload: false,
        });
      }
    },

    // 提交数据给后端，去注册激活证书
    *submitActivationForm({ payload }, { call, put }) {
      try {
        // 关闭检查证书是否存在的动画
        yield put({
          type: "setLoadingLicense",
          payload: false,
        });

        // 显示提交发送email动画
        yield put({
          type: "setSubmittingActivationForm",
          payload: true,
        });
        const response = yield call(sendActivationUrlByEmail, payload);
        //console.log('发送激活url的 response: ', response);
        // 关闭提交发送email动画
        yield put({
          type: "setSubmittingActivationForm",
          payload: false,
        });
        yield put({
          type: "setActivationUrlResp",
          payload: response,
        });
      } catch (error) {
        //console.log('激活连接请求错误：', error);
        // 关闭发送email的动画
        yield put({
          type: "setSubmittingActivationForm",
          payload: false,
        });

        // 设置显示结果内容
        yield put({
          type: "setActivationUrlResp",
          payload: error.hasOwnProperty("data") ? error.data : error,
        });
      }
    },

    *createSingleSDLicense({ payload }, { call, put }) {
      try {
        message.loading({
          key: "createLicense",
          content: "Creating SD single license.",
          duration: 10,
        });
        const resp = yield call(createNewSDLicense, payload);
        console.log("create resp: ", resp);
        const { code, message: msg } = resp;
        if (code === "CreateLicenseOk") {
          message.success({
            key: "createLicense",
            content: msg,
            duration: 10,
          });
        } else {
          throw new Error(msg);
        }
      } catch (error) {
        message.error({
          key: "createLicense",
          content: error.message,
          duration: 10,
        });
      }
    },

    // 激活证书
    *activateLicnese({ payload }, { call, put }) {
      try {
        // 1. we should set isActivatingLicense to true, so that users konw the current status.
        yield put({
          type: "setActivatingLicenseStatus",
          payload: true,
        });

        // 2. trigger to activate the license.
        const response = yield call(activateLicnese, payload);
        // console.log('激活证书 response: ', response);

        // 3. Set the activation result.
        yield put({
          type: "setActivationResp",
          payload: response,
        });

        // 4. Hide the activation spinner
        yield put({
          type: "setActivatingLicenseStatus",
          payload: false,
        });
      } catch (error) {
        // 关闭检查动画
        yield put({
          type: "setLoadingLicense",
          payload: false,
        });
        // 设置显示结果内容
        yield put({
          type: "setActivationResp",
          payload: error.hasOwnProperty("data") ? error.data : error,
        });
        // Hide the activation spinner
        yield put({
          type: "setActivatingLicenseStatus",
          payload: false,
        });
      }
    },

    // changePin
    *changeLicnesePin({ payload }, { call, put }) {
      try {
        const response = yield call(changeLicnesePin, payload);
        console.log("修改证书PIN的 response: ", response);

        yield put({
          type: "setChangePinResp",
          payload: response,
        });
      } catch (error) {
        // 关闭检查动画
        yield put({
          type: "setLoadingLicense",
          payload: false,
        });
        // 设置显示结果内容
        yield put({
          type: "setChangePinResp",
          payload: error.hasOwnProperty("data") ? error.data : error,
        });
      }
    },
  },

  subscriptions: {
    setup({ dispatch, history }) {},
  },

  reducers: {
    save(state, action) {
      return { ...state, ...action.payload };
    },
    saveLicense(state, action) {
      return {
        ...state,
        license: action.payload,
      };
    },
    setLoadingLicense(state, action) {
      return {
        ...state,
        isCheckingLicense: action.payload,
      };
    },
    setSubmittingActivationForm(state, action) {
      return {
        ...state,
        isSubmittingActivationForm: action.payload,
      };
    },
    setActivationResp(state, action) {
      return {
        ...state,
        activationResponse: action.payload,
      };
    },
    setActivationUrlResp(state, action) {
      return {
        ...state,
        sendActivationFormResponse: action.payload,
      };
    },
    setChangePinResp(state, action) {
      return {
        ...state,
        changPinResp: action.payload,
      };
    },

    // 设置是否处于激活证书中
    setActivatingLicenseStatus(state, action) {
      // console.log('是否正处于激活证书状态: ', action.payload);

      return {
        ...state,
        isActivatingLicense: action.payload,
      };
    },
  },
};
