import React, { useEffect, useRef, useState } from "react";
import { message } from "antd";

import { request } from "../../../utils/request/index";

import apiGateway from "../../../config/api";
const base = apiGateway().url;
// const base = process.env.NODE_ENV === "development" ? "http://localhost:3000": ;
console.log("环境变量：", {
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_ENV: process.env.REACT_APP_ENV,
});

export const PaypalCheckout = ({ quantity, licenseNumber }) => {
  const paypal = useRef();
  const [transactionStatus, setTransactionStatus] = useState(null);

  useEffect(() => {
    window.paypal
      .Buttons({
        // Order is created on the server and the order id is returned
        createOrder: async () => {
          // const order = await fetch(
          //   `${base}/dev/datAshurSD/createCheckoutOrder`,
          //   {
          //     method: "POST",

          //     headers: {
          //       "Content-Type": "application/json",
          //     },
          //     body: JSON.stringify({
          //       cart: {
          //         productId: licenseNumber,
          //         quantity,
          //       },
          //     }),
          //   }
          // ).then((response) => response.json());
          try {
            const order = await request.post(
              `/datAshurSD/createCheckoutOrder`,
              {
                data: {
                  cart: {
                    productId: licenseNumber,
                    quantity,
                  },
                },
              }
            );

            console.log("order:", order);
            const {
              data: { id },
            } = order;

            return id;
          } catch (error) {
            console.log("error", error);
          }
        },
        onShippingChange: (data, actions) => {
          const { shipping_address: shippingAddress, orderID } = data;

          if (shippingAddress.country_code === "GB") {
            return actions.resolve();
          } else {
            // non UK address, we need to remove tax from total price
            // return actions.reject();

            return fetch(`${base}/datAshurSD/patchCheckoutOrder`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                orderID,
                countryCode: shippingAddress.country_code,
              }),
            }).then((resp) => resp.json());
          }
        },

        onApprove: async (data, actions) => {
          const { orderID } = data;

          const details = await actions.order.capture();
          console.log("捕获order成功的响应: ", details);
          const { status, payer, id: paymentID } = details;
          if (status === "COMPLETED") {
            const response = await request.post(
              `/datAshurSD/captureCheckoutOrder`,
              {
                data: {
                  orderID,
                  paymentID,
                  payer,
                },
              }
            );

            console.log("支付订单后升级证书capacity的响应:", response);
            setTransactionStatus("success");
            message.success(response?.message);
            // window.location.reload();
          }
        },
        onError: (err) => {
          console.log(err);
          setTransactionStatus("failure");
        },
      })
      .render(paypal.current);
  }, []);

  if (transactionStatus === "success") {
    console.log("payment success");
  }

  if (transactionStatus === "failure") {
    console.log("payment failure");
  }
  return <div ref={paypal}></div>;
};
