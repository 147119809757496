import React, { useEffect, useCallback } from "react";
import { Space, Form, Input, Button, Result, Spin } from "antd";
import { useIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "dva";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const ChangePinForm = ({
  licenseNumber,
  license,
  isCheckingLicense,
  isSubmittingChangePinForm,
  changPinResp,
  dispatch,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { capacity } = license ? license : {};

  // 获取证书信息
  useEffect(() => {
    // 获取证书是否激活等信息
    dispatch({
      type: "keywriterInfoModel/getLicneseByLicenseNumber",
      payload: licenseNumber,
    });
  }, []);

  // 提交修改证书PIN
  const submitChangePinFormData = useCallback((values) => {
    console.log("提交change pin 表格的数据：", values);
    dispatch({
      type: "keywriterInfoModel/changeLicnesePin",
      payload: { ...values },
    });
  }, []);

  // 获取证书信息中...
  if (isCheckingLicense) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "checkingLicenseTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "Checking license",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  } else {
    // 证书信息过程已经走完
    if (!license || Object.keys(license).length === 0) {
      return (
        <Result
          status="error"
          title={intl.formatMessage({ id: "LicenseNotFoundTitle" })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({ id: "LicenseNotFoundSubtitle" })}
              </span>
              <span>{`${intl.formatMessage({
                id: "License Number",
              })}: ${licenseNumber}`}</span>
            </Space>
          }
        />
      );
    }
    // if (isActivated !== true) {
    //     return <Result
    //         status="info"
    //         title={intl.formatMessage({ id: 'licenseActivatedAlreadyTitle' })}
    //         subTitle={<Space direction='vertical'>
    //             <span >{intl.formatMessage({ id: 'licenseActivatedAlreadySubtitle' })}</span>
    //             <span >{`${intl.formatMessage({ id: 'License Number' })}: ${licenseNumber}`}</span>
    //             <span>{`${intl.formatMessage({ id: 'License Capacity' })}: ${capacity}`}</span>
    //         </Space>}
    //     />
    // }
  }

  // 判断修改PIN的响应
  if (!changPinResp || !changPinResp.hasOwnProperty("code")) {
    // do nothing. just go to show the form
  } else if (changPinResp.code === "RedirectToActivationFormPage") {
    return (
      <Result
        status="warning"
        title={intl.formatMessage({ id: "LicenseNotActivatedTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "RedirecetToActivateSubtitle" })}
            </span>
          </Space>
        }
        extra={[
          <Button type="primary" href={changPinResp.data.activationFormUrl}>
            {intl.formatMessage({ id: "GoToActivate" })}
          </Button>,
        ]}
      />
    );
  } else if (changPinResp.code === "LicenseNotFound") {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "InvalidActivationLinkTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{intl.formatMessage({ id: "LicenseNotFoundSubtitle" })}</span>
          </Space>
        }
      />
    );
  } else if (changPinResp.code === "ChangePinOk") {
    return (
      <Result
        status="success"
        title={
          <span style={{ lineHeight: 1 }}>
            {`${intl.formatMessage({ id: "licensePinChangeOkTitle" })}`}
          </span>
        }
        subTitle={
          <span>{`${intl.formatMessage({
            id: "License Number",
          })}: ${licenseNumber}`}</span>
        }
      />
    );
  } else if (changPinResp.code === "LicenseHasActivated") {
    return (
      <Result
        status="info"
        title={intl.formatMessage({ id: "licenseActivatedAlreadyTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "licenseActivatedAlreadySubtitle" })}
            </span>
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
            <span>{`${intl.formatMessage({
              id: "License Capacity",
            })}: ${capacity}`}</span>
          </Space>
        }
      />
    );
  } else if (changPinResp.code === "LicenseAndPinDisMatch") {
    <Result
      status="error"
      title={intl.formatMessage({ id: "licenseAndPinDisMatchTitle" })}
      subTitle={
        <Space direction="vertical">
          <span>
            {intl.formatMessage({ id: "licenseAndPinDisMatchSubtitle" })}
          </span>
          <span>{`${intl.formatMessage({
            id: "License Number",
          })}: ${licenseNumber}`}</span>
        </Space>
      }
    />;
  } else {
    console.log("code ===", changPinResp.code);
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "licenseActivationFormFailedTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({
                id: "licenseActivationFormFailedSubtitle",
              })}
            </span>
          </Space>
        }
      />
    );
  }

  // 提交注册证书中...
  if (isSubmittingChangePinForm) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "isSubmittingPinFormTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  }

  // 显示证书是否激活
  if (
    !changPinResp ||
    !changPinResp.hasOwnProperty("code") ||
    (license && Object.keys(license).length > 0)
  ) {
    return (
      <Form
        {...formItemLayout}
        form={form}
        onFinish={submitChangePinFormData}
        initialValues={{
          licenseNumber: licenseNumber,
        }}
      >
        <h2 style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "Change your license PIN" })}
        </h2>

        <h4
          style={{
            textAlign: "center",
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 15,
            marginBottom: 25,
          }}
        >
          {intl.formatMessage({ id: "changePinSubtitle" })}
        </h4>

        {/* License Number */}
        <Form.Item
          name="licenseNumber"
          label={intl.formatMessage({ id: "License Number" })}
          rules={[
            {
              message: "The input is not valid license number!",
            },
            {
              required: true,
              message: "Please input your license number!",
            },
          ]}
        >
          <Input readOnly />
        </Form.Item>

        {/* Current PIN */}
        <Form.Item
          name="oldPin"
          label={intl.formatMessage({ id: "Current PIN" })}
          dependencies={["newPin"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please enter your current PIN!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPin") === value) {
                  return Promise.reject(
                    new Error(
                      "Current PIN should be different from the new PIN."
                    )
                  );
                }
                return Promise.resolve(); // 当前PIN和New PIN不一样才是我们想要的
              },
            }),
          ]}
        >
          <Input.Password maxLength={64} />
        </Form.Item>

        {/* New PIN */}
        <Form.Item
          name="newPin"
          label={intl.formatMessage({ id: "New PIN" })}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please input your new PIN!",
            },
          ]}
        >
          <Input.Password maxLength={64} />
        </Form.Item>

        {/* Confirm PIN */}
        <Form.Item
          name="confirmedPin"
          label={intl.formatMessage({ id: "Conform PIN" })}
          dependencies={["newPin"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your PIN!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two PINs that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password maxLength={64} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: "Submit" })}
          </Button>
        </Form.Item>
      </Form>
    );
  } else {
    return null;
  }
};

export default connect(({ keywriterInfoModel }) => ({
  license: keywriterInfoModel.license,
  isCheckingLicense: keywriterInfoModel.isCheckingLicense,
  isSubmittingChangePinForm: keywriterInfoModel.isSubmittingChangePinForm,
  changPinResp: keywriterInfoModel.changPinResp,
}))(ChangePinForm);
