import { ClientID_iStorageLive, ClientID_iStorageSandBox } from "../constants";
const getPaypalClientId = () => {
  const { NODE_ENV, REACT_APP_ENV } = process.env;
  if (REACT_APP_ENV === "production") {
    return ClientID_iStorageLive;
  }
  if (REACT_APP_ENV === "test") {
    return ClientID_iStorageSandBox;
  }

  if (REACT_APP_ENV === "development") {
    return ClientID_iStorageSandBox;
  }

  if (NODE_ENV === "production") {
    return ClientID_iStorageLive;
  }

  if (NODE_ENV === "test") {
    return ClientID_iStorageSandBox;
  }

  return ClientID_iStorageSandBox;
};

export const paypalClientId = getPaypalClientId();
