import { extend } from "umi-request";
import apiGateway from "../../config/api";

// Create a new instance of request where common settings is put for all requests.
export const request = extend({
  prefix: apiGateway().url,
  timeout: 60000, // 1min
  headers: {
    "X-API-KEY": "I1MnKSkF0R7tO7OWAxCCC11BygRonqTl7gCTKEOY", // API keys deployed by sls.
  },
});
