import React, { useEffect } from "react";
import { Space, Result, Spin, notification } from "antd";
import { useIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "dva";

const ActivateResult = ({
  licenseNumber,
  signature,
  data,
  license,
  isCheckingLicense,
  activationResponse,
  isActivatingLicense,
  dispatch,
}) => {
  const intl = useIntl();

  const { capacity } = license ? license : {};

  // 激活
  useEffect(() => {
    // 获取证书是否激活等信息
    dispatch({
      type: "keywriterInfoModel/activateLicnese", // 这里model要改下
      payload: { licenseNumber, signature, data },
    });
  }, []);

  // 获取证书信息
  useEffect(() => {
    // 获取证书是否激活等信息
    dispatch({
      type: "keywriterInfoModel/getLicneseByLicenseNumber", // 这里model要改下
      payload: licenseNumber,
    });
  }, []);

  // 获取证书信息中...
  if (isCheckingLicense) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "checkingLicenseTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "Checking license",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  }

  // 判断是否处于激活证书中
  if (isActivatingLicense) {
    // 正在激活证书，请等待
    return showActivatingSpinner(intl, licenseNumber);
  }

  // 显示证书是否激活
  if (!activationResponse || !activationResponse.hasOwnProperty("code")) {
    // console.log("activationResponse = ", activationResponse);

    // 正在激活证书，请等待
    return showActivatingSpinner(intl, licenseNumber);
  } else if (activationResponse.code === "LicenseNotFound") {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "InvalidActivationLinkTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{intl.formatMessage({ id: "LicenseNotFoundSubtitle" })}</span>
          </Space>
        }
      />
    );
  } else if (activationResponse.code === "LicenseActivationOk") {
    // TODO 这里要根据证书开头SDKW来判断，是否对license number进行处理

    if (String(licenseNumber).startsWith("SDKW")) {
      const trimedLicenseNumber = String(licenseNumber).replace("SDKW-", "");

      notification.success({
        message: intl.formatMessage({ id: "licenseActivationOkTitle2" }),
        description: (
          <Space direction="vertical">
            <span>{`Dear Customer,`}</span>
            <span>
              {`Please enter license number: ${trimedLicenseNumber}, when you launch datAshur SD KeyWriter app.`}
            </span>
          </Space>
        ),
        duration: null,
      });

      return (
        <Result
          status="success"
          title={intl.formatMessage({ id: "licenseActivationOkTitle2" })}
          subTitle={
            <Space direction="vertical">
              <h3>
                {intl.formatMessage({ id: "licenseActivationOkSubtitle2" })}
              </h3>
              <span>
                {" "}
                <strong>{`${intl.formatMessage({
                  id: "License Number",
                })}:${trimedLicenseNumber}`}</strong>
              </span>
              <span>{`${intl.formatMessage({
                id: "License Capacity",
              })}: ${capacity}`}</span>
            </Space>
          }
        />
      );
    } else {
      // default return
      return (
        <Result
          status="success"
          title={intl.formatMessage({ id: "licenseActivationOkTitle" })}
          subTitle={
            <Space direction="vertical">
              <h3>
                {intl.formatMessage({ id: "licenseActivationOkSubtitle" })}
              </h3>
              <span>{`${intl.formatMessage({
                id: "License Number",
              })}: ${licenseNumber}`}</span>
              <span>{`${intl.formatMessage({
                id: "License Capacity",
              })}: ${capacity}`}</span>
            </Space>
          }
        />
      );
    }
  } else if (activationResponse.code === "LicenseHasActivated") {
    return (
      <Result
        status="info"
        title={intl.formatMessage({ id: "licenseActivatedAlreadyTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "licenseActivatedAlreadySubtitle" })}
            </span>
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
            <span>{`${intl.formatMessage({
              id: "License Capacity",
            })}: ${capacity}`}</span>
          </Space>
        }
      />
    );
  } else if (activationResponse.code === "VerifySignaturError") {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "InvalidActivationLinkTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "InvalidActivationLinkSubtitle" })}
            </span>
          </Space>
        }
      />
    );
  } else {
    console.log("activationResponse.code ===", activationResponse.code);
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "licenseActivationFailedTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "licenseActivationFailedSubtitle" })}
            </span>
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
            {capacity ? (
              <span>
                {`${intl.formatMessage({
                  id: "License Capacity",
                })}:
               ${capacity}`}
              </span>
            ) : null}
          </Space>
        }
      />
    );
  }
};

export default connect(({ keywriterInfoModel }) => ({
  license: keywriterInfoModel.license,
  isCheckingLicense: keywriterInfoModel.isCheckingLicense,
  activationResponse: keywriterInfoModel.activationResponse,
}))(ActivateResult);

// 显示激活中的spinner
function showActivatingSpinner(intl, licenseNumber) {
  return (
    <Result
      status="info"
      icon={
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      }
      title={intl.formatMessage({ id: "activatingLicenseTitle" })}
      subTitle={
        <Space direction="vertical">
          <span>{`${intl.formatMessage({
            id: "Activating license",
          })}: ${licenseNumber}`}</span>
        </Space>
      }
    />
  );
}
