import BasicPage from './basicPage/index';
import React from 'react';
import Header from './keywriter/renew/Header';
import { Result } from 'antd';



const NotFound = () => {



    // Initialise result.
    const NotFoundResult = () => <Result
        status="404"
        title={<span style={{ color: 'white' }}>{404}</span>}
        subTitle={<span style={{ color: 'white' }}>{'The request URL was not found on this server.'}</span>}
    />

    return <BasicPage HeaderComponent={Header} ContentComponent={NotFoundResult} />
}

export default NotFound;