import BasicPage from '../../components/basicPage/index';
import React from 'react';
import RenewCard from '../../components/keywriter/renew/RenewCard';
import Header from '../../components/keywriter/renew/Header';
// import { connect } from 'dva';


const KeywriterRenewalPage = ({ match: { params: { licenseNumber } } }) => {

    const renewal = () => {
        return <RenewCard licenseNumber={licenseNumber} />
    }

    return licenseNumber ? <BasicPage HeaderComponent={Header} ContentComponent={renewal} /> : null
}

export default (KeywriterRenewalPage);