import React, { useCallback } from "react";
import { Form, Input, Button } from "antd";
import { useIntl } from "react-intl";
import { connect } from "dva";
import LicenseType from "./licenseTypeEnum";
import { useState } from "react";

const email = "info@istorage-uk.com";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const ActivateForm = ({
  licenseNumber,
  license,
  isCheckingLicense,
  isSubmittingActivationForm,
  sendActivationFormResponse,
  activationResponse,
  dispatch,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const [isSendLicenseBtnDisabled, setIsSendLicenseBtnDisabled] =
    useState(false);

  // 提交注册激活证书
  const createLicense = useCallback((values) => {
    console.log("提交激活表格的数据：", values);

    const { capacity, orderId } = values;
    const params = {
      quota: capacity,
      order_id: orderId ?? "",
      cms_order_id: orderId ?? "",
      type: LicenseType.sdLicenseForNormalPurchase,
    };
    setIsSendLicenseBtnDisabled(true);
    dispatch({
      type: "keywriterInfoModel/createSingleSDLicense",
      payload: params,
    });
  }, []);

  return (
    <Form {...formItemLayout} form={form} onFinish={createLicense}>
      <h2 style={{ textAlign: "center" }}>
        {intl.formatMessage({ id: "Create an SD single license" })}
      </h2>

      <h4
        style={{
          textAlign: "center",
          paddingLeft: 40,
          paddingRight: 40,
          marginTop: 15,
          marginBottom: 25,
        }}
      >
        {`License will be sent to ${email}`}
      </h4>

      {/* <Form.Item
        name="email"
        label={intl.formatMessage({ id: "Reciepiant Email" })}
      >
        <Input readOnly defaultValue= />
         
      </Form.Item> */}

      {/* Capacity */}
      <Form.Item
        name="capacity"
        label={intl.formatMessage({ id: "Capacity" })}
        rules={[
          {
            message: "The input is not valid!",
          },
          {
            required: true,
            message: "Please input a capcaity!",
          },
        ]}
      >
        <Input type={"number"} max={10000} min={1} />
      </Form.Item>

      {/* CMS order number or other order id.  */}
      <Form.Item
        name="orderId"
        label={intl.formatMessage({ id: "Order ID" })}
        rules={[
          {
            required: true,
            message: "Please input the order ID!",
          },
        ]}
      >
        <Input maxLength={50} minLength={3} />
      </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button
          type="primary"
          htmlType="submit"
          disabled={isSendLicenseBtnDisabled}
        >
          {intl.formatMessage({ id: "Send License" })}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default connect(({ keywriterInfoModel }) => ({
  license: keywriterInfoModel.license,
  isCheckingLicense: keywriterInfoModel.isCheckingLicense,
  isSubmittingActivationForm: keywriterInfoModel.isSubmittingActivationForm,
  sendActivationFormResponse: keywriterInfoModel.sendActivationFormResponse,
  activationResponse: keywriterInfoModel.activationResponse,
}))(ActivateForm);
