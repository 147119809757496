/*
 * @Author: Peng Liu
 * @Date: 2021-08-03 11:35:47
 * @LastEditTime: 2022-01-07 14:49:37
 */

const en = {
    // Card title
    'title': 'Upgrade your iStorage KeyWriter License',
    'congTitle': 'Congratulations!',
    'subtitle': 'Please enter the number of devices you wish to add to your existing license.',

    // Form
    'License Number': 'License Number',
    'License number': 'License number',
    'License Capacity': 'License Capacity',
    'License New Capacity': 'New Capacity',
    'Current capacity': 'Current license capacity',
    'currentCapaictyDescription': 'The current number of devices that can be cloned using your existing license:',
    'Drive Amount': 'Additional devices required',
    'driveAmountDescription': 'The additional number of devices that you wish to purchase/add to your existing license:',
    'Price': 'Price',

    // Result
    'paymentSuccessful': 'Payment successful',
    'renewSuccessTitle': 'Your license has been upgraded.',

    'paidPriceIncorrectTitle': 'An incorrect amount has been paid',
    'paidPriceIncorrectSubtitle': 'Please check your email and contact us at support@istorage-uk.com .',

    'paymentOkRenewFailedTitle': 'Your payment is successful but it was failed to upgrade your KeyWriter license capacity.',
    'paymentOkRenewFailedSubTitle': 'Please contact us at support@istorage-uk.com .',
    'renewNote': 'Your new license capacity will be reflected on the KeyWriter application.',

    'paymentOkUpdatingLicenseTitle': 'Upgrading...',
    'Updating license': 'Thanks for your payment. We are updating your license',

    'licenseNotFoundTitle': 'License Not Found',
    'notFoundLicenseSubtitle': 'Sorry, we cannot find the license',

    'footerText': 'iStorage is the trusted global leader of PIN authenticated, hardware encrypted data storage devices. We deliver the most innovative products to securely store and protect data to Military specified encryption levels, safeguarding your valuable business information whilst ensuring compliance to regulations and directives.',

    'checkingLicenseTitle': 'Checking License',
    'Checking license': 'Checking License',
    'licenseActivationOkTitle': 'Activation Successful',
    'licenseActivationOkTitle2': 'Activation Successful. ',
    'licenseActivationOkSubtitle': 'Your license is activated.',
    'licenseActivationOkSubtitle2': 'Please launch datAshur SD KeyWriter using below license number.',
    'licenseActivatedAlreadyTitle': 'Activated',
    'licenseActivatedAlreadySubtitle': 'Your license has been activated before.',
    'InvalidActivationLinkTitle': 'Invalid Activation Link',
    'InvalidActivationLinkSubtitle': 'Your activation link is invalid.',
    'LicenseNotFoundTitle': 'License Not Found.',
    'LicenseNotFoundSubtitle': 'Your License cannot be found.',
    'licenseActivationEmailSentTitle': 'Activation email has successfully been sent',
    'licenseActivationEmailSentSubtitle': 'Please check your email.',
    'licenseOneClickActivationEmailSentTitle': 'Activation email has been successfully sent to',
    'licenseOneClickActivationEmailSentSubtitle': 'Please check your inbox.',
    'isSubmittingActivationFormTitle': 'Sending activation email',
    'Activate': 'Activate',
    'Activate License': 'Activate License',
    'Activating license': 'Activating license',
    'activatingLicenseTitle': 'Activating License',
    'Phone': 'Telephone number',
    'Company Address': 'Address',
    'Company Name': 'Company',
    'Full Name': 'Full name',
    'PIN': 'PIN',
    'Email': 'Email',
    'Please enter the license and PIN number that was provided by your preferred iStorage partner': 'Please fill out the form below to complete your license activation. You will also need to enter the license number and PIN that was provided to you.',
    'Activate your license': 'Activate your license',
    'Thank you for your purchase!': 'Thank you for your purchase!',
    // change PIN
    'Change your license PIN': 'Change your PIN',
    'changePinSubtitle': "Please complete the fields below with your current PIN and new PIN, then click the 'Submit' button.",
    'Current PIN': 'Current PIN',
    'New PIN': 'New PIN',
    'Conform PIN': 'Confirm PIN',
    'Submit': 'Submit',
    'LicenseNotActivatedTitle': 'Not Activated License',
    'RedirecetToActivateSubtitle': 'Please click below button to activate your license before changing the PIN.',
    'GoToActivate': 'Go to activate',
    'licenseAndPinDisMatchTitle': 'Mismatach of license and PIN',
    'licenseAndPinDisMatchSubtitle': 'Your license mismatch the PIN.',
    'licensePinChangeOkTitle': 'Your license PIN has been successfully changed.',
    'licensePinChangeOkSubtitle': '',
    'licenseActivationFormFailedTitle': 'Failed to activate your license',
    'licenseActivationFormFailedSubtitle': 'Please check the form data you submitted.',
    'LicenseAndPinDisMatchTitle': 'Incorrect license number or PIN',
    'LicenseAndPinDisMatchSubtitle': 'Please refresh this page and enter correct license number and PIN.',
    'InvalidFullNameTitle': "Invalid customer's full name",
    'InvalidFullNameSubtitle': 'Please refresh this page and re-enter your full name.',
    'licenseActivationFailedTitle': 'License Activation',
    'licenseActivationFailedSubtitle': 'Error on the license activation',
}

export default en;