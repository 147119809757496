import { resolvePriceNumberString } from "./resolvePriceNumberString";
import { resolveTotal } from "./resolveTotal";
import { resolveTax } from "./resolveTax";

export const resolvePriceWithoutVAT = ({ totalAmount, countryCode }) => {
  const total = resolveTotal(totalAmount);
  const tax = resolveTax({ totalAmount: total, countryCode });

  return resolvePriceNumberString(total - tax);
};
