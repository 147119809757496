import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Form,
  Input,
  InputNumber,
  Space,
  Tooltip,
  Result,
  Spin,
  Radio,
} from "antd";
import { useIntl } from "react-intl";
import { QuestionCircleOutlined, LoadingOutlined } from "@ant-design/icons";
// import PayPalPayButton from "../../PaypalPayButton";
import { PaypalCheckout as PayPalPayButton } from "../../payment/paypal-checkout/Checkout";
import { connect } from "dva";
import {
  resolvePriceWithoutVAT,
  resolveTax,
} from "../../../utils/resolver/index";
import { Helmet } from "react-helmet";
import { paypalClientId } from "../../payment/utils/paypal-client-id";

const RenewForm = ({ licenseNumber, keywriterRenewModel, dispatch }) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const {
    license,
    unitPrice: price,
    updateLicenseCapacityOk,
    paymentOk,
    isPaymentCorrect,
    isUpdatingLicense,
  } = keywriterRenewModel;
  const { capacity: currentCapacity } = license ? license : {};
  const [quantity, setQuantity] = useState(0);
  const [isUKCustomer, setIsUKCustomer] = useState(undefined);

  // console.log('RenewForm 获取license capacity: ', currentCapacity, price);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
      }),
    []
  );

  useEffect(() => {
    // 检查证书是否存在
    dispatch({
      type: "keywriterRenewModel/getLicneseByLicenseNumber",
      payload: licenseNumber,
    });
  }, [licenseNumber]);

  // 更新当前证书容量
  useEffect(() => {
    form.setFieldsValue({
      currentCapacity,
    });
  }, [currentCapacity]);

  const labelStyle = {
    font: "normal normal 500 14px/20px Raleway",
  };

  const inputBoxStyle = {
    height: 30,
    width: "100%",
    background:
      "transparent linear-gradient(180deg, #FBFBFB 0%, #F2F2F2 100%) 0% 0% no-repeat padding-box",
    border: "1px solid #707070",
    borderRadius: 5,
    font: "normal normal 700 14px/20px Arial",
  };

  const inputNumberBoxStyle = {
    ...inputBoxStyle,
    font: "normal normal 700 14px/20px Arial ",
  };

  const priceStyle = {
    font: "normal normal 600 16px/20px Arial ",
    marginBottom: 20,
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  // const domNodeRef = useRef(null);

  // 设备数量发生变化的回调
  const onDriveAmountChanged = (value) => {
    // console.log('value = ', value)
    const qty = value ? value : 0;

    setQuantity(qty);

    dispatch({
      // type: 'keywriterRenewModel/getTotalPrice',
      type: "keywriterRenewModel/getUnitPrice",
      payload: qty,
    });
  };

  const ref1 = useRef();
  const ref2 = useRef();

  // if license in its initial state. return null.
  if (license === undefined) {
    return null;
  }

  if (isUpdatingLicense) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "paymentOkUpdatingLicenseTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "Updating license",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  }

  //支付金额错误
  if (paymentOk && isPaymentCorrect === false) {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "paidPriceIncorrectTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "paidPriceIncorrectSubtitle" })}
            </span>
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
            <span>{`${intl.formatMessage({
              id: "License Capacity",
            })}: ${currentCapacity}`}</span>
          </Space>
        }
      />
    );
  }

  // 续费成功后显示的内容
  if (paymentOk && isPaymentCorrect && updateLicenseCapacityOk) {
    return (
      <>
        <p
          style={{ font: "normal normal 700 22px/24px Raleway", marginTop: 20 }}
        >
          {
            // Thanks for renewing license text
            paymentOk &&
            isPaymentCorrect && // 支付金额是否正确
            updateLicenseCapacityOk && // 更新容量成功
            isUpdatingLicense == false // 是否正在更新证书
              ? intl.formatMessage({ id: "congTitle" })
              : intl.formatMessage({ id: "title" })
          }
        </p>
        <Result
          status="success"
          title={
            <Space direction="vertical">
              <span>{intl.formatMessage({ id: "paymentSuccessful" })}</span>
              <span style={{ fontSize: 17 }}>
                {intl.formatMessage({ id: "renewSuccessTitle" })}
              </span>
            </Space>
          }
          subTitle={
            <Space direction="vertical">
              <span>{`${intl.formatMessage({ id: "renewNote" })}`}</span>
              <span>{`${intl.formatMessage({
                id: "License Number",
              })}: ${licenseNumber}`}</span>
              <span>{`${intl.formatMessage({
                id: "License New Capacity",
              })}: ${currentCapacity}`}</span>
            </Space>
          }
        />
      </>
    );
  }

  if (paymentOk && isPaymentCorrect && updateLicenseCapacityOk === false) {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "paymentOkRenewFailedTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {intl.formatMessage({ id: "paymentOkRenewFailedSubTitle" })}
            </span>
            <span>{`${intl.formatMessage({
              id: "License Number",
            })}: ${licenseNumber}`}</span>
            <span>{`${intl.formatMessage({
              id: "License Capacity",
            })}: ${currentCapacity}`}</span>
          </Space>
        }
      />
    );
  }

  // 如果license = {}
  if (Object.keys(license).length === 0) {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "licenseNotFoundTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "notFoundLicenseSubtitle",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  }

  return (
    Object.keys(license).length > 0 && (
      <div ref={ref1}>
        <Helmet>
          <script
            src={`https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=GBP&commit=true`}
          ></script>
        </Helmet>
        <p
          style={{ font: "normal normal 700 22px/24px Raleway", marginTop: 20 }}
        >
          {
            // Thanks for renewing license text
            paymentOk &&
            isPaymentCorrect && // 支付金额是否正确
            updateLicenseCapacityOk && // 更新容量成功
            !isUpdatingLicense // 是否正在更新证书
              ? intl.formatMessage({ id: "congTitle" })
              : intl.formatMessage({ id: "title" })
          }
        </p>

        <p
          style={{
            font: "normal normal 700 16px/18px Raleway",
            marginBottom: 20,
          }}
        >
          {intl.formatMessage({ id: "subtitle" })}
        </p>
        <div style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Form
            ref={ref2}
            form={form}
            layout="vertical"
            name="basic"
            initialValues={{
              licenseNumber: licenseNumber,
              currentCapacity: currentCapacity,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {/* License Number */}
            <Form.Item
              label={
                <Space>
                  <span style={labelStyle}>
                    {intl.formatMessage({ id: "License number" })}
                    {":"}
                  </span>
                </Space>
              }
              name="licenseNumber"
            >
              <Input style={inputBoxStyle} readOnly />
            </Form.Item>

            {/* 当前容量 */}
            {
              <Form.Item
                label={
                  <Space>
                    <span style={labelStyle}>
                      {intl.formatMessage({ id: "Current capacity" })}
                      {":"}
                    </span>
                    <Tooltip
                      title={
                        intl.formatMessage({
                          id: "currentCapaictyDescription",
                        }) +
                        " " +
                        licenseNumber
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                style={{ textAlign: "left" }}
                name="currentCapacity"
              >
                <InputNumber
                  style={inputNumberBoxStyle}
                  max={500}
                  min={1}
                  readOnly
                />
              </Form.Item>
            }

            {/* UK customer? */}
            <Form.Item
              label={
                <Space>
                  <span style={labelStyle}>
                    {intl.formatMessage({
                      id: "UK Customer?",
                      defaultMessage: "UK Customer ?",
                    })}
                  </span>
                </Space>
              }
              name="isUKCustomer"
              rules={[
                {
                  required: true,
                  message: "Please select",
                },
              ]}
            >
              <Radio.Group
                onChange={(e) => {
                  setIsUKCustomer(e.target.value);
                }}
                value={isUKCustomer}
                style={{ display: "flex" }}
                data-test-id="customer-radio"
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {/* 需要购买的设备数量 */}
            {
              <Form.Item
                label={
                  <Space>
                    <span style={labelStyle}>
                      {intl.formatMessage({ id: "Drive Amount" })}
                      {":"}
                    </span>
                    <Tooltip
                      title={
                        intl.formatMessage({ id: "driveAmountDescription" }) +
                        " " +
                        licenseNumber
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </Space>
                }
                name="driveAmount"
                style={{ textAlign: "left" }}
                rules={[
                  {
                    required: true,
                    message: "Please input amount of drives!",
                  },
                ]}
              >
                <InputNumber
                  style={inputBoxStyle}
                  autoFocus={true}
                  max={50000}
                  min={1}
                  onChange={onDriveAmountChanged}
                />
              </Form.Item>
            }
            {console.log({ price })}
            {/*当前价格 */}
            {price && price * quantity > 0 && isUKCustomer !== undefined && (
              <div style={priceStyle}>
                {`${intl.formatMessage({
                  id: "Price",
                  defaultMessage: "Price",
                })}: ${formatter.format(
                  Number(
                    resolvePriceWithoutVAT({
                      totalAmount: price * quantity,
                      countryCode: "GB",
                    })
                  )
                )} (excl. VAT)`}
              </div>
            )}

            {/*当前Tax */}
            {price && price * quantity > 0 && isUKCustomer !== undefined && (
              <div style={priceStyle}>{`${intl.formatMessage({
                id: "VAT",
                defaultMessage: "VAT",
              })}: ${formatter.format(
                Number(
                  resolveTax({
                    totalAmount: price * quantity,
                    countryCode: isUKCustomer ? "GB" : undefined,
                  })
                )
              )}`}</div>
            )}

            <Form.Item wrapperCol={{ span: 24 }}>
              {price && price * quantity > 0 && isUKCustomer !== undefined && (
                <div>
                  <PayPalPayButton
                    total={(price * quantity).toFixed(2)}
                    unitPrice={price}
                    quantity={quantity}
                    licenseNumber={licenseNumber}
                    isUKCustomer={isUKCustomer}
                  />
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </div>
    )
  );
};

export default connect(({ keywriterRenewModel }) => ({ keywriterRenewModel }))(
  RenewForm
);
