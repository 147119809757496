import React, { useCallback, useState } from "react";
import { Space, Form, Input, Button, Result, Spin, Divider } from "antd";
import { useIntl } from "react-intl";
import { LoadingOutlined } from "@ant-design/icons";
import { connect } from "dva";
import { showActivationSubmitionResult } from "./showActivationSubmitionResult";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 8,
      offset: 8,
    },
  },
};

const ActivateFormManually = ({
  licenseNumber,
  license,
  isCheckingLicense,
  isSubmittingActivationForm,
  sendActivationFormResponse,
  activationResponse,
  dispatch,
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const { capacity, isActivated } = license ? license : {};
  const [enteredLicneseNumber, setLicenseNumber] = useState();
  // 提交注册激活证书
  const submitActivationForm = useCallback((values) => {
    console.log("提交激活表格的数据：", values);
    dispatch({
      type: "keywriterInfoModel/submitActivationForm",
      payload: { ...values },
    });
  }, []);

  const onLicneseNumberChanged = useCallback((e) => {
    const value = e.target.value;
    setLicenseNumber(value);
  }, []);

  // 获取证书信息中...
  if (isCheckingLicense) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "checkingLicenseTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{`${intl.formatMessage({
              id: "Checking license",
            })}: ${licenseNumber}`}</span>
          </Space>
        }
      />
    );
  } else {
    // 如果证书已经被激活，则告诉用户证书已经激活过了。
    if (isActivated === true) {
      return (
        <Result
          status="info"
          title={intl.formatMessage({ id: "licenseActivatedAlreadyTitle" })}
          subTitle={
            <Space direction="vertical">
              <span>
                {intl.formatMessage({ id: "licenseActivatedAlreadySubtitle" })}
              </span>
              <span>
                {licenseNumber &&
                  `${intl.formatMessage({
                    id: "License Number",
                  })}: ${licenseNumber}`}
              </span>
              <span>
                {capacity &&
                  `${intl.formatMessage({
                    id: "License Capacity",
                  })}: ${capacity}`}
              </span>
            </Space>
          }
        />
      );
    }
  }

  // 如果证书不存在，显示相关错误信息
  if (!activationResponse || !activationResponse.hasOwnProperty("code")) {
  } else if (activationResponse.code === "LicenseNotFound") {
    return (
      <Result
        status="error"
        title={intl.formatMessage({ id: "InvalidActivationLinkTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>{intl.formatMessage({ id: "LicenseNotFoundSubtitle" })}</span>
          </Space>
        }
      />
    );
  }

  // 提交注册证书中...
  if (isSubmittingActivationForm) {
    return (
      <Result
        status="info"
        icon={
          <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
        }
        title={intl.formatMessage({ id: "isSubmittingActivationFormTitle" })}
        subTitle={
          <Space direction="vertical">
            <span>
              {licenseNumber &&
                `${intl.formatMessage({
                  id: "License Number",
                })}: ${licenseNumber}`}
            </span>
          </Space>
        }
      />
    );
  }

  // 显示证书是否激活
  if (
    !sendActivationFormResponse ||
    !sendActivationFormResponse.hasOwnProperty("code")
  ) {
    console.log("submit activation form result: ", sendActivationFormResponse);

    return (
      <Form
        {...formItemLayout}
        form={form}
        onFinish={submitActivationForm}
        initialValues={{
          licenseNumber: licenseNumber,
        }}
      >
        {/* <h2 style={{ textAlign: "center" }}>
                {intl.formatMessage({ id: 'Thank you for your purchase!' })}
            </h2> */}

        <h2 style={{ textAlign: "center" }}>
          {intl.formatMessage({ id: "Activate your license" })}
        </h2>

        <h4
          style={{
            textAlign: "center",
            paddingLeft: 40,
            paddingRight: 40,
            marginTop: 15,
            marginBottom: 25,
          }}
        >
          {intl.formatMessage({
            id: "Please enter the license and PIN number that was provided by your preferred iStorage partner",
          })}
        </h4>

        {/* Email  */}
        <Form.Item
          name="email"
          label={intl.formatMessage({ id: "Email" })}
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your Email!",
            },
          ]}
        >
          <Input maxLength={50} />
        </Form.Item>

        {/* License Number */}
        <Form.Item
          name="licenseNumber"
          label={intl.formatMessage({ id: "License Number" })}
          rules={[
            {
              message: "The input is not valid license number!",
            },
            {
              required: true,
              message: "Please input your license number!",
            },
          ]}
        >
          <Input onChange={onLicneseNumberChanged} />
        </Form.Item>

        {/* PIN */}
        <Form.Item
          name="pin"
          label={intl.formatMessage({ id: "PIN" })}
          rules={[
            {
              message: "The input is not valid PIN!",
            },
            {
              required: true,
              message: "Please input your license PIN!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  getFieldValue("pin").length === 7 &&
                  String(enteredLicneseNumber).length > 20 &&
                  String(enteredLicneseNumber).startsWith("SDKW")
                ) {
                  // 只针对SDKW过长的证书
                  return Promise.reject(
                    "If your PIN is 7-digit, please add '0' in the beginning to make it 8-digit PIN. For example, if your PIN is 1234567 then you should enter 01234567. "
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input maxLength={8} />
        </Form.Item>

        <Divider />

        {/* User full name  */}
        <Form.Item
          name="name"
          label={intl.formatMessage({ id: "Full Name" })}
          rules={[
            {
              required: true,
              message: "Please input your full name!",
            },
          ]}
        >
          <Input maxLength={50} minLength={3} />
        </Form.Item>

        {/* Company Name */}
        <Form.Item
          name="companyName"
          label={intl.formatMessage({ id: "Company Name" })}
          rules={[
            {
              required: false,
              message: "Please input your company name!",
            },
          ]}
        >
          <Input maxLength={50} />
        </Form.Item>

        {/* Company address */}
        <Form.Item
          name="companyAddress"
          label={intl.formatMessage({ id: "Company Address" })}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input maxLength={100} />
        </Form.Item>

        {/* Company Name */}
        <Form.Item
          name="phone"
          label={intl.formatMessage({ id: "Phone" })}
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input maxLength={20} />
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({ id: "Activate License" })}
          </Button>
        </Form.Item>
      </Form>
    );
  } else if (sendActivationFormResponse.code === "SendActivationUrlEmailOk") {
    return (
      <Result
        status="success"
        title={
          <span style={{ lineHeight: 1 }}>
            {`${intl.formatMessage({
              id: "licenseOneClickActivationEmailSentTitle",
            })} ${sendActivationFormResponse.data.email}`}
          </span>
        }
        subTitle={
          <span style={{ fontSize: 16 }}>
            {intl.formatMessage({
              id: "licenseOneClickActivationEmailSentSubtitle",
            })}
          </span>
        }
      />
    );
  } else {
    return showActivationSubmitionResult(
      sendActivationFormResponse,
      licenseNumber,
      capacity,
      intl
    );
  }
};

export default connect(({ keywriterInfoModel }) => ({
  license: keywriterInfoModel.license,
  isCheckingLicense: keywriterInfoModel.isCheckingLicense,
  isSubmittingActivationForm: keywriterInfoModel.isSubmittingActivationForm,
  sendActivationFormResponse: keywriterInfoModel.sendActivationFormResponse,
  activationResponse: keywriterInfoModel.activationResponse,
}))(ActivateFormManually);
