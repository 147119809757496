/*
 * @Author: Peng Liu
 * @Date: 2021-08-09 16:59:42
 * @LastEditTime: 2021-08-09 17:01:55
 */
const previousApiGateway = {
  url: "https://kw.istorage-api.com/keywriter",
};

const kwDevApiGateway = {
  url: "https://kw-dev.istorage-api.com/keywriter",
};

const kwLocalApiGateway = {
  url: "http://localhost:3000/dev", //
};

const kwTestApiGateway = {
  url: "https://kw-test.istorage-api.com/keywriter",
};

const kwProdApiGateway = {
  url: "https://kw-prod.istorage-api.com/keywriter",
};

const apiGateway = () => {
  const { NODE_ENV, REACT_APP_ENV } = process.env;

  switch (REACT_APP_ENV) {
    case "local":
      return kwLocalApiGateway;

    case "development":
      return kwDevApiGateway;

    case "test":
      return kwTestApiGateway;

    case "production":
      return kwProdApiGateway;

    default:
      break;
  }

  switch (NODE_ENV) {
    case "development":
      return kwDevApiGateway;

    case "test":
      return kwTestApiGateway;

    case "production":
      return kwProdApiGateway;

    default:
      return kwDevApiGateway;
  }
};

export default apiGateway;
