/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 13:15:33
 * @LastEditTime: 2023-02-18 18:45:29
 */
import { extend } from "umi-request";
import { notification } from "antd";

import apiGateway from "../../config/api";

// 统一错误处理函数
const errorHandler = function (error) {
  const codeMap = {
    404: "Cannot find resources.",
    // ....
  };
  if (error.response) {
    // 请求已发送但服务端返回状态码非 2xx 的响应
    console.log(error.response.status);
    console.log(error.response.headers);
    console.log(error.data);
    console.log(error.request);
    console.log(codeMap[error.data.status]);
    notification.error({
      message: "Activation Error",
      description: error.data.message,
    });
  } else {
    // 请求初始化时出错或者没有响应返回的异常
    console.log("请求初始化时出错或者没有响应返回的异常:", error);
  }

  throw error; // 如果throw. 错误将继续抛出.

  // 如果return, 则将值作为返回. 'return;' 相当于return undefined, 在处理结果时判断response是否有值即可.
  // return {some: 'data'};
};

// Create a new instance of request where common settings is put for all requests.
const request = extend({
  prefix: apiGateway().url,
  timeout: 60000, // 1min
  errorHandler,
  // headers: {
  //     "X-API-KEY": "I1MnKSkF0R7tO7OWAxCCC11BygRonqTl7gCTKEOY" // API keys deployed by sls.
  // }
});

// 激活证书get request
export function activateLicnese(params) {
  try {
    const { licenseNumber, signature, data } = params;
    const url = `/datAshurSD/activation?licenseNumber=${licenseNumber}&signature=${signature}&data=${data}`;
    return request.get(url);
  } catch (error) {
    throw error;
  }
}

/**
 * 向用户发送激活连接
 * @param {*} params
 */
export function sendActivationUrlByEmail(params) {
  try {
    // const { licenseNumber, email } = params;
    const url = `/datAshurSD/sendActivationUrlEmail`; // datAshurSD/sendActivationUrlEmail
    return request.post(url, {
      data: params,
    });
  } catch (error) {
    console.log("sendActivationUrlByEmail error:", error);
    throw error;
  }
}

/**
 * 向后台请求创建一个SD single 证书
 * @param {*} params
 * @returns
 */
export function createNewSDLicense(params) {
  try {
    // const { licenseNumber, email } = params;
    const url = `/datAshurSD/order/internal`; // "/datAshurSD/order"
    return request.post(url, {
      data: params,
    });
  } catch (error) {
    console.log("createNewSDLicense error:", error);
    throw error;
  }
}
