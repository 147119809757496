/*
 * @Author: Peng Liu
 * @Date: 2021-08-03 11:36:16
 * @LastEditTime: 2021-08-03 11:36:28
 */

import en from './en-US';
import zh from './zh-CN';

const messages = {
    en,
    zh
}

export default messages;