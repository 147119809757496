import { createFromIconfontCN } from '@ant-design/icons/lib'; // 在ant-design-pro中使用iconfont

// 在ant-design-pro中使用iconfont

const Icon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2719976_z7npq6p2b1g.js', // icon库的Symbol  

});

export function getIcon(iconName, fontSize = null) {
    if (String(iconName).toLowerCase() === 'istorage') {
        return <Icon type='icon-iStorage' style={{ fontSize: fontSize }} />
    }
    if (String(iconName).toLowerCase() === 'rmc') {
        return <Icon type='icon-cloudAshur_RMC' style={{ fontSize: fontSize }} />
    }
    if (String(iconName).toLowerCase() === 'keywriter') {
        return <Icon type='icon-KeyWriter_logo' style={{ fontSize: fontSize }} />
    }

    if (String(iconName).toLowerCase() === 'facebook') {
        return <Icon type='icon-Facebook_icon' style={{ fontSize: fontSize }} />
    }
    if (String(iconName).toLowerCase() === 'twitter') {
        return <Icon type='icon-Twitter_icon' style={{ fontSize: fontSize }} />
    }
    if (String(iconName).toLowerCase() === 'youtube') {
        return <Icon type='icon-Youtube_icon' style={{ fontSize: fontSize }} />
    }

    if (String(iconName).toLowerCase() === 'linkedin') {
        return <Icon type='icon-LinkedIn_icon' style={{ fontSize: fontSize }} />
    }
    if (String(iconName).toLowerCase() === 'instagram') {
        return <Icon type='icon-Instagram_icon' style={{ fontSize: fontSize }} />
    }


    return null;

}
