import BasicPage from "./basicPage/index";
import React, { useEffect } from "react";
import Header from "./keywriter/renew/Header";
import { Result, Image } from "antd";

const Home = ({ history }) => {
  useEffect(() => {
    history.push("https://istorage-uk.com");
  }, []);

  // 图片
  const home = () => (
    <Result
      icon={
        <>
          <Image
            style={{ marginLeft: "-25%" }}
            width={1000}
            preview={false}
            src="https://istorage-uk.com/wp-content/uploads/2021/06/Product-range_may2021_with-blue-diskAshur2.png"
          />
        </>
      }
    />
  );

  return <BasicPage HeaderComponent={Header} ContentComponent={home} />;
};

export default Home;
