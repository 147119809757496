/*
 * @Author: Peng Liu
 * @Date: 2021-08-06 13:15:33
 * @LastEditTime: 2021-11-18 12:17:32
 */
import { extend } from "umi-request";
import { notification } from "antd";

import apiGateway from "../../config/api";

// 统一错误处理函数
const errorHandler = function (error) {
  const codeMap = {
    404: "Cannot find resources.",
    // ....
  };
  if (error.response) {
    // 请求已发送但服务端返回状态码非 2xx 的响应
    // console.log(error.response.status);
    // console.log(error.response.headers);
    // console.log(error.data);
    // console.log(error.request);
    // console.log(codeMap[error.data.status]);

    notification.error({
      message: "Change PIN Error",
      description: error.data.message,
    });
  } else {
    // 请求初始化时出错或者没有响应返回的异常
    console.log("请求初始化时出错或者没有响应返回的异常:", error);
  }

  throw error; // 如果throw. 错误将继续抛出.

  // 如果return, 则将值作为返回. 'return;' 相当于return undefined, 在处理结果时判断response是否有值即可.
  // return {some: 'data'};
};

// Create a new instance of request where common settings is put for all requests.
const request = extend({
  prefix: apiGateway().url,
  timeout: 60000, // 1min
  errorHandler,
  // headers: {
  //     "X-API-KEY": "I1MnKSkF0R7tO7OWAxCCC11BygRonqTl7gCTKEOY" // API keys deployed by sls.
  // }
});

/**
 * POST 修改证书PIN
 * @param {JSON Object} params {licenseNumber,oldPin,newPin }
 */
export function changeLicnesePin(params) {
  try {
    // const { licenseNumber, email } = params;
    const url = `/datAshurSD/changePin`;
    return request.post(url, {
      data: params,
    });
  } catch (error) {
    console.log("changePin error:", error);
    throw error;
  }
}
