/*
 * @Author: Peng Liu
 * @Date: 2021-08-02 17:58:59
 * @LastEditTime: 2021-11-24 11:25:59
 */
import { Row, Col, Space } from 'antd';
// import { TwitterOutlined, FacebookOutlined, LinkedinOutlined, YoutubeOutlined, InstagramOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';// 导入多语言
import { getIcon } from '../Icon';



const Footer = () => {
    const intl = useIntl();

    return <div style={{
        // position: 'fixed',
        left: 0,
        // bottom: 5,
        width: '100%',
    }} >
        <Row >
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }}>
                <span style={{ font: 'normal normal 300 10px/12px Raleway' }}>
                    {
                        // istorage introduction
                        intl.formatMessage({ id: 'footerText' })
                    }
                </span>
            </Col>
        </Row>
        <Row style={{ fontSize: 20, }}>
            <Col xs={{ span: 24, offset: 0 }} lg={{ span: 8, offset: 8 }}>
                <Space size='large' >
                    <a style={{ color: '#F2F2F2' }} target='_blank' rel="noreferrer" href='https://www.facebook.com/iStorageLtd'  >
                        {getIcon('facebook')}
                    </a>
                    <a style={{ color: '#F2F2F2' }} target='_blank' rel="noreferrer" href='https://twitter.com/iStorage_ltd'>
                        {getIcon('twitter')}
                    </a>
                    <a style={{ color: '#F2F2F2' }} target='_blank' rel="noreferrer" href='https://www.linkedin.com/company/istorage-limited'>
                        {getIcon('linkedin')}
                    </a>
                    <a style={{ color: '#F2F2F2' }} target='_blank' rel="noreferrer" href='https://www.youtube.com/channel/UC9Y8LnQtGlMf81dFl_JtZ7w'>
                        {getIcon('youtube')}
                    </a>
                    <a style={{ color: '#F2F2F2' }} target='_blank' rel="noreferrer" href='https://www.instagram.com/istorage_limited'>
                        {getIcon('instagram')}
                    </a>
                </Space>
            </Col>
        </Row>
    </div >

}

export default Footer;