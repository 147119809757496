/*
 * @Author: Peng Liu
 * @Date: 2023-02-18 16:13:30
 * @LastEditTime: 2023-02-18 17:03:06
 */
import React from "react";
import { Card } from "antd";
// import { useIntl } from 'react-intl';// 导入多语言
import { getIcon } from "../../Icon";
import NewLicenseForm from "./NewLicenseForm";
import { PhoneOutlined, MailOutlined } from "@ant-design/icons";

/**
 * Keywriter activation container
 * @param string data 加密后的用户登记数据
 */
const ActivatationContainer = ({ licenseNumber, signature, data }) => {
  // const intl = useIntl();

  return (
    <Card
      size="small"
      headStyle={{ height: 50 }}
      title={
        <div>
          <span style={{ float: "left", marginTop: "-30px" }}>
            {getIcon("istorage", 90)}
          </span>
          <span style={{ float: "right", marginTop: "-42px" }}>
            {getIcon("keywriter", 125)}
          </span>
        </div>
      }
      style={{
        width: "100%",
        borderRadius: 5,
        height: "auto",
      }}
      bodyStyle={{
        height: "auto",
      }}
      actions={[
        <a href="tel:+4402089916260">
          <PhoneOutlined key="setting" /> +44 (0)20 8991 6260
        </a>,
        <a href="mailto:support@istorage-uk.com">
          <MailOutlined key="ellipsis" /> support@istorage-uk.com
        </a>,
      ]}
    >
      <NewLicenseForm />
    </Card>
  );
};

export default ActivatationContainer;
